<template>
    <div>
        <vue-breadcrumbs :breadCrumbs='breadCrumbs'/>
        <div class="vue_card">
            <booking-form @submitHandler='submitHandler' :limit='limit.length'/>
            <div v-if="ready" class="vue_card__info">
                <div class="card_header">
                    <vue-avatar :options="{img: cardDetails.to_location.main_image}"/>
                    <div>
                        <p>Экскурсия</p>
                        <p>{{cardDetails.to_location.name}}</p>
                        <p><img src="@/assets/images/ui/location_orange.svg" alt="">{{cardDetails.to_location.location.name}}</p>
                    </div>
                </div>
                <div class="card_content">
                    <div class="mb-3">
                        <v-icon color="primary">mdi-account</v-icon>
                        <span v-text="allPeopleRead()"></span>
                    </div>
                    <div class="mb-5">
                        <span style="font-size:14px;color:lightgrey">Тариф для детей начинается от {{ details.child_age }} и ниже</span>
                    </div>
                    <div class="dates">
                        <v-icon color="primary">mdi-calendar</v-icon>
                        <p>{{$moment(new Date(cardDetails.from_date * 1000)).format('DD-MMM, YYYY')}} - {{$moment(new Date(cardDetails.to_date * 1000)).format('DD-MMM, YYYY')}}</p>
                    </div>
                    <div v-if="Number(details.adult_price || details.adult_ticket) * Number($route.query.adult)" class="people_count">
                        <div>
                            <p v-text="peopleRead($route.query.adult, 'взрослый', 'взрослых', 'взрослых') + ` * ${details.adult_ticket} KZT`"></p>
                        </div>
                        <p class="price">{{((details.adult_price || details.adult_ticket) * Number($route.query.adult))}} KZT</p>
                    </div>
                    <div v-if="Number(details.child_price || details.child_ticket ) * Number($route.query.child)" class="people_count">
                        <div>
                            <p v-text="peopleRead($route.query.child, 'ребенок', 'ребенка', 'детей') + ` * ${details.child_ticket} KZT`"></p>
                        </div>
                        <p class="price">{{(details.child_price || details.child_ticket) * Number($route.query.child)}} KZT</p>
                    </div>
                    <div v-if="Number(details.pensioner_price || details.pensioner_ticket) * Number($route.query.pensioner)" class="people_count">
                        <div>
                            <p v-text="peopleRead($route.query.pensioner, 'пенсионер', 'пенсионера', 'песионеров') + ` * ${details.pensioner_ticket} KZT`"></p>
                        </div>
                        <p class="price">{{(details.pensioner_price || details.pensioner_ticket) * Number($route.query.pensioner)}} KZT</p>
                    </div>
                </div>
                <div class="total_sum">
                    <p>Итого</p>
                    <p>{{cardDetails.price}} KZT</p>
                </div>
            </div>
        </div>
        <vue-booking-modal :dialog='dialog' :exept="'Экскурсии'" @closeDialog='closeDialog'/>
    </div>
</template>

<script>
import VueBreadcrumbs from '@/components/ui/vueBreadcrumbs.vue'
import VueAvatar from '@/components/ui/vueAvatar.vue'
import BookingCardPeople from '@/components/booking/bookingCardPeople.vue'
import BookingForm from '@/components/booking/bookingForm.vue'
import VueBookingModal from '@/components/ui/vueBookingModal.vue'
import { toursService } from '@/services/tours.js'
import { setModel } from '@/utils/formatMask.js'
export default {
    components: {
        VueBreadcrumbs,
        VueAvatar,
        BookingCardPeople,
        BookingForm,
        VueBookingModal
    },
    data: () => ({
        ready: false,
        dialog: false,
        cardDetails: {},
        peopleCount: [],
        details: {}
    }),
    computed: {
        breadCrumbs() {
            let seatQuery = {
                adult: this.$route.query.adult, 
                child: this.$route.query.child, 
                pensioner: this.$route.query.pensioner, 
                from_date: this.$route.query.from_date, 
                from_location: this.$route.query.from_location
            }
            return [
                {
                    text: 'Бронирование туров',
                    to: '/booking/tours/',
                    query: seatQuery
                },
                {
                    text: 'Выбор места',
                    to: `/booking/tours/${this.$route.params.id}/`,
                    query: seatQuery
                },
                {
                    text: 'Бронирование',
                    to: `/booking/tours/${this.$route.params.id}/card/`,
                }
            ]
        },
        limit() {
            const { adult, child, pensioner } = this.$route.query
            const arr = []
            for(let i = 0; i < adult; i++) {
                arr.push('adult')
            }
            for(let i = 0; i < child; i++) {
                arr.push('child')
            }
            for(let i = 0; i < pensioner; i++) {
                arr.push('pensioner')
            }
            return arr
        },
    },
    created() {
        this.getDetails()
    },
    methods: {
        allPeopleRead() {
            let adult = this.$route.query.adult;
            let child = this.$route.query.child;
            let pensioner = this.$route.query.pensioner;
            let a = "", b = "", c = "";
            if (adult == 1) a = adult + " взрослый";
            else a = adult + " взрослых";

            if(child == 1) b = '1 ребенок'
            else if(child > 1 && child < 5) b = child + ' ребенка'
            else b = child + ' ребенков'

            if(pensioner == 1) c = '1 пенсионер'
            else if(pensioner > 1 && pensioner < 5) c = pensioner + ' пенсионера'
            else c = pensioner + ' пенсионеров'

            return `${a}, ${b}, ${c}`;
        },
        peopleRead(numb, first, second, third) {
            let a;
            if(numb == 1) a = '1 ' + first
            else if(numb > 1 && numb < 5) a = numb + ' ' + second
            else a = numb + ' ' + third
            return `${a}`;
        },
        async getDetails() {
            try {
                this.$loading(true)
                const { adult, child, pensioner } = this.$route.query
                this.cardDetails = await toursService.getSingleActiveTours({id: this.$route.params.id, adult, child, pensioner})
                this.details = await toursService.getSingleCard(this.$route.params.id)
                console.log(this.details);
                this.ready = true
            } catch(e) {
                console.error(e);
            } finally {
                this.$loading(false)
            }
        },
        closeDialog() {
            this.dialog = false
            this.$router.push('/booking/residence')
        },
        async submitHandler(data) {
            const formData = this.$copy(data)
            formData.user.forEach(element => {
                element.phone_number = element.phone_number.replace(/[() -]/g,'')
            });
            const tour_order_clients = []
            formData.user.forEach(el => {
                tour_order_clients.push({...el}) // birth_date: new Date(this.$moment(el.birth_date, 'DD.MM.YYYY').format('MM-DD-YYYY')).getTime() / 1000
            })
            const diff = this.limit.length - formData.user.length
            for (let j = 0; j < diff; j++) {
                tour_order_clients.push(this.$copy(tour_order_clients[0]))
            }
            tour_order_clients.forEach((user, index) => {
                user.seat_place = Number(this.$route.query.seats.split(',')[index])
            })
            this.limit.forEach((tariff, index) => {
                tour_order_clients[index].tariff = tariff
            })
            const postData = {
                seal_channel: formData.seal_channel,
                route: this.$route.params.id,
                tour_order_clients,
            }
            await toursService.postTourOrders(postData)
            this.dialog = true
        },
    }
}
</script>

<style lang="scss" scoped>
.condition{
    background-color: whitesmoke;
    padding: 11px;
    padding-bottom: 1px;
    margin: 7px 0 !important;
    border-radius: 5px;
}
.vue_card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    grid-column-gap: 20px;
    &__info {
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(146, 178, 193, 0.3);
        border-radius: 5px;
        padding: 20px;
    }
}
.card_header {
    display: grid;
    grid-template-columns: minmax(193px, 40%) 60%;
    min-height: 181px;
    grid-column-gap: 20px;
    align-items: start;
    p {
        margin-bottom: 0;
        &:nth-of-type(1) {
            font-size: 12px;
            line-height: 14px;
            color: rgba(2, 16, 17, 0.5);
            margin-top: 10px;
        }
        &:nth-of-type(2) {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            margin: 6px 0;
        }
        &:nth-of-type(3) {
            font-size: 12px;
            line-height: 14px;
            color: #000000;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img {
                margin-right: 6px;
            }
        }
    }
}
.card_content {
    padding-bottom: 30px;
    padding-top: 40px;
    margin-bottom: 30px;
    border-bottom: 1px solid #E9E9E9;
    p {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
    }
    .dates {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;
        p {
            margin-left: 12px;
        }
    }
    .people_count {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        &:last-of-type {
            margin-bottom: 0;
        }
        div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            p {
                margin-left: 12px;
            }
        }
    }
}
.total_sum {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
    }
}
</style>